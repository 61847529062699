import React from 'react';
import TreatmentHistory from './Partials/treatmentHistory';

const ViewPatient = () => {
  return (
    <TreatmentHistory />
  );
};

export default ViewPatient;
