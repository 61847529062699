import React from 'react';

const NotFound = () => {
  return (
    <>
      404 Page
    </>
  );
};

export default NotFound;
